//
// Font.scss
//

//Google font - Lobster Two
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:wght@400;700&display=swap');

// Font - Switzer
@font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Light.woff2') format('woff2'),
        url('../fonts/Switzer-Light.woff') format('woff'),
        url('../fonts/Switzer-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Regular.woff2') format('woff2'),
        url('../fonts/Switzer-Regular.woff') format('woff'),
        url('../fonts/Switzer-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Medium.woff2') format('woff2'),
        url('../fonts/Switzer-Medium.woff') format('woff'),
        url('../fonts/Switzer-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Semibold.woff2') format('woff2'),
        url('../fonts/Switzer-Semibold.woff') format('woff'),
        url('../fonts/Switzer-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Bold.woff2') format('woff2'),
        url('../fonts/Switzer-Bold.woff') format('woff'),
        url('../fonts/Switzer-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}