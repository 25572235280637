//
// _ecommerce.scss
//

//ecommerce landing page

#ecommerceHero {

    .carousel-control-prev,
    .carousel-control-next {
        opacity: 0;
        height: 40px;
        width: 40px;
        background: $primary;
        top: 43%;
        border-radius: $border-radius;
        font-size: 24px;
        transition: all 0.5s ease;
        transform: rotate(45deg);

        i {
            transform: rotate(-45deg);
        }
    }

    .carousel-control-next {
        right: 45px;
    }

    .carousel-control-prev {
        left: 45px;
    }

    &:hover {

        .carousel-control-prev,
        .carousel-control-next {
            opacity: 0.85;
        }
    }
}

.ecommerce-home {
    position: relative;
    padding: 160px 0 90px;
    background-size: cover;
    background-position: center;
    min-height: 710px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:1200px) {
        min-width: 100%;
    }
}

.product-banner-1 {
    position: relative;

    .product-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 350px;
    }

    .bg-overlay {
        background: linear-gradient(270deg, $black, transparent);
    }

    .blue {
        &.bg-overlay {
            background: linear-gradient(45deg, #0764619c, transparent);
        }
    }

    &.right {
        .product-content {
            right: 0px;
        }
    }
}

.ecommerce-about {
    padding: 180px 0 85px;
    position: relative;
}

.effect {
    .ecommerce-effect {
        position: absolute;
        height: 15rem;
        width: 15rem;
        filter: grayscale(33%) blur(40px);
        opacity: 0.1;
        border-radius: 50%;
        top: 0;
        left: 150px;

        &:last-child {
            bottom: 20px;
            top: auto;
        }
    }
}

.ecommerce-about-team {
    position: relative;
    padding: 150px 0 100px;

    .team-box {
        .team-img {
            position: relative;
            border-radius: 50%;

            img {
                padding: 14px;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                height: 8px;
                width: 8px;
                border-radius: 50%;
            }

            &::before {
                background-color: $success;
                left: 60%;
            }

            &::after {
                background-color: $danger;
                left: 38%;
                bottom: 0%;
            }
        }
    }
}

.ecommerce-about-cta {
    position: relative;
    z-index: 1;
    margin-bottom: -100px;
}

.ecommerce-subscript {
    input {
        padding: 15px 135px 15px 24px;
    }

    .btn {
        position: absolute;
        right: 4px;
        top: 4px;
    }
}

.ecommerce-product {
    display: flex;

    .sidebar {
        width: 350px;

        &.small-sidebar {
            width: 280px;
        }
    }

    .offer-bar {
        width: 350px;

        .offer-banner {
            height: 520px;
        }
    }

    @media (max-width:1200px) {
        flex-direction: column;

        .sidebar,
        .offer-bar,
        .sidebar.small-sidebar {
            width: 100%;
        }
    }

    @media (max-width:1440px) {
        .offer-bar {
            display: none;
        }
    }
}

//ecommerce widgets
.clothe-colors {
    li {

        input[type="radio"],
        input[type="checkbox"] {
            display: none;
        }

        label {
            display: inline-block;
            font-size: 16px;
            border-radius: 5px;
            text-transform: none;
            letter-spacing: 0;
            position: relative;
        }

        input[type="radio"]:checked+label,
        input[type="checkbox"]:checked+label {
            &::after {
                content: "\f33e";
                font-family: Phosphor;
                position: absolute;
                inset: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
            }
        }
    }
}

.clothe-size {
    li {

        input[type="radio"],
        input[type="checkbox"] {
            display: none;
        }

        label {
            display: inline-block;
            font-size: $font-size-sm;
            border-radius: 5px;
            text-transform: none;
            letter-spacing: 0;
            position: relative;
        }

        input[type="radio"]:checked+label,
        input[type="checkbox"]:checked+label {
            border-color: $border-color;
            background-color: $primary;
            color: $white;
        }
    }
}

.product-banner-2 {
    .product-content {
        position: absolute;
        right: 102px;
        transform: translateY(-50%);
        top: 50%;

        @media (max-width:425px) {
            right: 30px;
            margin-top: 10px;
        }
    }
}

// product create

.error-msg {
    display: none;
    color: var(--#{$prefix}danger-text);
    font-size: .875em;
}

.was-validated .error-msg {
    display: block;
}

// Product
.categories-filter {
    .list-inline-item {
        position: relative;
        margin-right: 0;

        a {
            display: block;
            color: var(--#{$prefix}secondary-color);
            font-weight: $font-weight-semibold;
            padding: 8px 15px;
            margin: 5px;
            cursor: pointer;
            background-color: var(--#{$prefix}light);
        }
    }
}

.product-lable {
    transform: rotate(-90deg) translateX(-60%);
    position: absolute;
    top: 0;
    left: -32px;
}

.element-item {
    transition: all 0.5s;

    .gallery-product {
        img {
            transition: all 0.5s;
        }
    }

    .product-btn {
        transition: all 0.5s;
        opacity: 0;
        position: relative;

        .add-btn {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

    }

    .gallery-product-actions {
        position: absolute;
        top: 15px;
        right: 15px;
        opacity: 0;

        .product-action {
            width: 34px;
            height: 34px;
        }
    }

    &:hover {
        .gallery-product {
            img {
                transform: scale(1.08);
            }
        }

        .gallery-product-actions {
            transition: all 0.5s;
            opacity: 1;
        }

        .product-btn {
            transform: translateY(-27px);
            opacity: 1;
        }
    }

}

.ecommerce-product-widgets {
    background-color: var(--#{$prefix}body-bg);

    .action {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .label {
        position: absolute;
        left: 10px;
        top: 10px;
    }
}

.ecommerce-deals-widgets {
    .gallery-product {
        img {
            height: 300px;
            width: 100%;
        }
    }

    .countdown-deals {
        .count-title {
            display: none;
        }

        .count-num {
            font-size: 18px;
            background-color: rgba($white, 0.6);
            filter: blur(15%);
        }
    }

    .content {
        margin-top: -120px;
        transition: all 0.5s ease;
    }

    &:hover {
        .content {
            transform: translateY(-10px);
        }
    }
}

// product slider

.latest-slider {

    .swiper-button-next,
    .swiper-button-prev {
        top: 22px;
    }

    .swiper-button-prev {
        right: 55px;
        left: auto;
    }
}

// Testimonial client
.testi-slider {
    .swiper-pagination {
        bottom: 0;
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0px 7px !important;
        position: relative;
    }

    .swiper-pagination-bullet-active {
        &:before {
            position: absolute;
            content: "";
            left: -5px;
            right: 0;
            border: 2px solid $secondary;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            top: -5px;
        }
    }

}

// client images
.client-box {
    .client-desc {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 25px;
            height: 60px;
            background-color: var(--#{$prefix}body-bg);
            border: 1px solid var(--#{$prefix}border-color);
            transform: rotate(67deg);
            border-color: transparent var(--#{$prefix}border-color) var(--#{$prefix}border-color) transparent;
            bottom: -30px;
            left: 80px;
            margin-top: 10px;
        }
    }
}

// client images
.client-images {
    display: inline-block;

    img {
        max-height: 40px;
        width: 200px;
        margin: 6px auto;
        transition: all 0.4s;
    }
}

// Landing contdown
.ecommerce-land-countdown {
    .countdownlist {
        .count-num {
            background-color: rgba($danger, 0.1);
            padding: 16px 8px;
            font-size: 22px;

            @media (max-width: 575.98px) {
                font-size: 16px;
                padding: 8px 6px;
            }
        }

        .count-title {
            display: none;

            @media (max-width: 575.98px) {
                font-size: 10px;
            }
        }
    }
}

// INSTAGRAM 
.insta-img {
    background-color: $secondary;
    display: block;
    overflow: hidden;
    position: relative;

    img {
        transition: all 0.5s ease;
    }

    i {
        opacity: 0;
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
        font-size: 35px;
        color: $white;
        transform: scale(1.5) rotate(-15deg);
    }

    &:hover {
        img {
            transform: scale(1.2) rotate(-3deg);
            opacity: 0.4;
        }

        i {
            opacity: 1;
            transform: scale(1) rotate(0deg);
        }
    }
}

// blog
.blog-date {
    width: 60px;
    height: 68px;
    text-align: center;
    position: absolute;
    right: 15px;
    margin-top: -70px;
    padding: 8px 0;
    border-top: 4px solid $primary;
    box-shadow: $box-shadow-lg;
}

// insta
.insta-lable {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    transform: translateY(-50%);
}

.categrory-box {
    position: relative;

    .categrory-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        transition: all 0.5s ease-in;
        z-index: 1;
    }

    &:hover {
        .categrory-content {
            display: inline;
        }
    }
}

@media (min-width: 992px) {
    .sticky-side-div {
        position: sticky;
        top: calc(#{$header-height} + 3.5rem);
    }
}

// product details
.productSwiper {
    .swiper-slide {
        @media (min-width: 768px) {
            height: 80px !important;
            margin-bottom: 15px;
        }

        .product-thumb {
            border: 1px solid var(--#{$prefix}border-color-translucent);
        }

        &.swiper-slide-thumb-active {
            .product-thumb {
                border-color: $primary;
            }
        }
    }
}

// Delivery page